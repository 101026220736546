/**
 * Global events manager
 */

import { TweenLite } from 'gsap';
import { EVENTS } from '~/config/constant';

/**
 * Events manager configuration
 * @type {Object}
 */
let eventsConfiguration = {
    /**
     * Component data
     * @return {Object}
     */
    data () {
        const data = {
            screen: {
                width: 0,
                height: 0
            }
        };

        return data;
    },

    /**
     * Component created
     * @return {Void}
     */
    created () {
        this.setupHandlers();
        this.setWindowSizes();
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Set up handler
         * @return {Void}
         */
        setupHandlers () {
            let handle = { resize: 0 };

            window.addEventListener(
                'resize',
                () => {
                    this.dispatch(EVENTS.WINDOW_RESIZE_IMMEDIATE);
                    this.setWindowSizes();

                    TweenLite.to(
                        handle,
                        0.25,
                        {
                            resize: 0.25,
                            onCompleteScope: this,
                            overwrite: true,
                            onComplete () {
                                this.dispatch(EVENTS.WINDOW_RESIZE);
                                handle.resize = 0;
                            }
                        }
                    );
                }
            );

            /*
            window.addEventListener(
                'wheel',
                () => {
                    console.log('wheeeeeeeeel');
                },
                { passive: true }
            );
            */

            /**
             * Key handler
             */
            document.addEventListener(
                'keyup',
                event => {
                    const keyCode = event.keyCode || event.which;

                    switch (keyCode) {
                        // escape
                        case 27:
                            this.dispatch(EVENTS.KEY_UP_ESCAPE);
                            break;

                        // left arrow
                        case 37:
                            this.dispatch(EVENTS.KEY_UP_LEFT);
                            break;

                        // right arrow
                        case 39:
                            this.dispatch(EVENTS.KEY_UP_RIGHT);
                            break;

                        // top arrow
                        case 38:
                            this.dispatch(EVENTS.KEY_UP_TOP);
                            break;

                        // bottom arrow
                        case 40:
                            this.dispatch(EVENTS.KEY_UP_BOTTOM);
                            break;
                    }
                }
            );
        },

        /**
         * Set window sizes
         */
        setWindowSizes () {
            /**
             * Window screen sizes
             */
            this.screen.width = window.innerWidth;
            this.screen.height = window.innerHeight;

            /**
             * Update css vars
             */
            document.documentElement.style.setProperty('--viewport-h', `${window.innerHeight}px`);
        },

        /**
         * Dispatch event
         * @param  {String} name Event name
         * @param {Any} params Additional params
         * @return {Void}
         */
        dispatch (name, params = false) {
            this.$emit(name, params);
        }
    }
};

/**
 * Create vue instance
 * @type {Vue}
 */
const eventsComponent = new Vue(eventsConfiguration);

/**
 * Module export
 */
export default eventsComponent;
