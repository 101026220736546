/**
 * Social wall slider
 */

import Swiper from 'swiperslider';
import UIkit from 'uikit';
import BasicMixin from '~/mixins/basic';
import { SWIPER, EVENTS } from '~/config/constant';

let socialWallConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'social-wall',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted () {
        let config = {};

        _.assign(
            config,
            SWIPER,
            {
                slidesPerView: 'auto',
                centerInsufficientSlides: true,
                setWrapperSize: false,
                pagination: false,
                navigation: {
                    nextEl: this.$el.querySelector('.swiper-button-next'),
                    prevEl: this.$el.querySelector('.swiper-button-prev')
                },
                on: {
                    slideChangeTransitionEnd: () => {
                        _.each(
                            this.$el.querySelectorAll('.image'),
                            image => {
                                UIkit.update(image);
                            }
                        );
                    }
                }
            }
        );

        this.slider = new Swiper(this.$refs.slider, config);

        this.$event.$on(
            EVENTS.APP_READY,
            () => {
                this.slider.init();
            }
        );
    }
};

/**
 * Register component
 * @type {Vue}
 */
const SocialWall = Vue.extend(socialWallConfiguration);

/**
 * Module export
 */
export default SocialWall;
