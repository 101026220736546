/**
 * Group
 */

import { TimelineMax, Power4, Power2, TweenMax } from 'gsap';
import { mapState } from 'vuex';
import BasicMixin from '~/mixins/basic';
import { EVENTS } from '~/config/constant';

let groupConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'group',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component data
     * @return {Object}
     */
    data () {
        let data = {
            active: false,
            transition: false
        };

        return data;
    },

    /**
     * Component computed data
     */
    computed: {
        /**
         * Map store state
         */
        ...mapState([ 'menu', 'search' ]),

        enabled () {
            return !this.menu && !this.search && !this.transition;
        }
    },

    /**
     * Data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Active item watcher
         * @param {Number} active - active index
         * @param {Number|Boolean} last - last index
         * @return {Void}
         */
        active (active, last) {
            const ELEMENTS = this.$el.querySelectorAll('.highlights-item');
            const CURRENT_SLIDE = ELEMENTS[active];
            const LAST_SLIDE = ELEMENTS[last];
            const DIRECTION = last > active ? 1 : -1;

            let lastSlideElements = [];
            let lastSlideTitle = false;
            let lastSlideExcerpt = false;
            let lastSlideCTA = false;
            let lastSlideDestination = active === 0 ? 100 : 50;

            let currentSlideElements = [];
            let currentSlideTitle = false;
            let currentSlideExcerpt = false;
            let currentSlideCTA = false;

            this.transition = true;

            let timeline = new TimelineMax(
                {
                    paused: true,
                    delay: last === false ? 1 : 0,
                    onStartScope: this,
                    onCompleteScope: this,
                    onStart () {
                        if (this.footer) {
                            this.hideFooter();
                        }
                    },
                    onComplete () {
                        this.transition = false;

                        timeline.kill();
                    }
                }
            );

            /**
             * Toggle header classname
             */
            if (CURRENT_SLIDE.classList.contains('light')) {
                document.querySelector('.header-inner').classList.add('dark');
                this.$el.querySelector('.highlights-invite').classList.add('dark');
            } else {
                document.querySelector('.header-inner').classList.remove('dark');
                this.$el.querySelector('.highlights-invite').classList.remove('dark');
            }

            /**
             * Appending label start
             * Starting point
             */
            timeline.addLabel('start');

            /**
             * Hide previous slide
             */
            if (LAST_SLIDE && LAST_SLIDE instanceof HTMLElement) {
                /**
                 * Tween scroll invite line down
                 */
                timeline.to(
                    this.$el.querySelector('.line-down'),
                    1,
                    {
                        scaleY: 0.4,
                        ease: Power4.easeOut
                    },
                    'start'
                );

                if (last === 0) {
                    lastSlideTitle = LAST_SLIDE.querySelector('h1');
                    lastSlideExcerpt = LAST_SLIDE.querySelector('.excerpt');
                    lastSlideCTA = LAST_SLIDE.querySelector('.cta-wrapper');

                    if (lastSlideTitle && lastSlideTitle instanceof HTMLElement) {
                        lastSlideElements.push(lastSlideTitle);
                    }

                    if (lastSlideExcerpt && lastSlideExcerpt instanceof HTMLElement) {
                        lastSlideElements.push(lastSlideExcerpt);
                    }

                    if (lastSlideCTA && lastSlideCTA instanceof HTMLElement) {
                        lastSlideElements.push(lastSlideCTA);
                    }

                    timeline
                        /**
                         * Hide intro
                         * Tween title & cta
                         */
                        .to(
                            lastSlideElements,
                            1.6,
                            {
                                autoAlpha: 0,
                                y: -300,
                                ease: Power4.easeOut
                            },
                            'start'
                        )
                        /**
                         * Scale intro image
                         */
                        .set(
                            LAST_SLIDE.querySelector('.intro .media'),
                            { scale: 1.06 }
                        );
                } else {
                    lastSlideTitle = LAST_SLIDE.querySelector('h2');
                    lastSlideExcerpt = LAST_SLIDE.querySelector('.excerpt');
                    lastSlideCTA = LAST_SLIDE.querySelector('.cta-wrapper');

                    if (lastSlideTitle && lastSlideTitle instanceof HTMLElement) {
                        lastSlideElements.push(lastSlideTitle);
                    }

                    if (lastSlideExcerpt && lastSlideExcerpt instanceof HTMLElement) {
                        lastSlideElements.push(lastSlideExcerpt);
                    }

                    if (lastSlideCTA && lastSlideCTA instanceof HTMLElement) {
                        lastSlideElements.push(lastSlideCTA);
                    }

                    timeline
                        /**
                         * Unset z-index
                         */
                        .set(
                            LAST_SLIDE,
                            { clearProps: 'z-index' },
                            'start'
                        )

                        /**
                         * Hide highlights element
                         * Tween title, excerpt & cta
                        */
                        .to(
                            lastSlideElements,
                            0.8,
                            {
                                autoAlpha: 0,
                                y: DIRECTION * 100,
                                ease: Power2.easeInOut
                            },
                            'start'
                        )

                        /**
                         * Tween last slide
                         */
                        .to(
                            LAST_SLIDE,
                            1.6,
                            {
                                yPercent: DIRECTION * lastSlideDestination,
                                ease: Power2.easeInOut
                            },
                            'start'
                        )
                        .set(
                            LAST_SLIDE,
                            { yPercent: DIRECTION * 100 }
                        );
                }
            }

            /**
             * Show selected slide
             */
            if (active === 0) {
                currentSlideTitle = CURRENT_SLIDE.querySelector('h1');
                currentSlideExcerpt = CURRENT_SLIDE.querySelector('.excerpt');
                currentSlideCTA = CURRENT_SLIDE.querySelector('.cta-wrapper');

                if (currentSlideTitle && currentSlideTitle instanceof HTMLElement) {
                    currentSlideElements.push(currentSlideTitle);
                }

                if (currentSlideExcerpt && currentSlideExcerpt instanceof HTMLElement) {
                    currentSlideElements.push(currentSlideExcerpt);
                }

                if (currentSlideCTA && currentSlideCTA instanceof HTMLElement) {
                    currentSlideElements.push(currentSlideCTA);
                }

                timeline
                    /**
                     * Show intro
                     * Tween title & cta
                     */
                    .staggerTo(
                        currentSlideElements,
                        1,
                        {
                            delay: 1,
                            autoAlpha: 1,
                            y: 0,
                            ease: Power2.easeOut
                        },
                        {
                            from: DIRECTION === -1 ? 'start' : 'end',
                            amount: 0.2
                        },
                        'start'
                    )
                    /**
                     * Tween image
                     */
                    .to(
                        CURRENT_SLIDE.querySelector('.media'),
                        1,
                        {
                            scale: 1,
                            ease: Power2.easeOut
                        },
                        'start'
                    );
            } else {
                currentSlideTitle = CURRENT_SLIDE.querySelector('h2');
                currentSlideExcerpt = CURRENT_SLIDE.querySelector('.excerpt');
                currentSlideCTA = CURRENT_SLIDE.querySelector('.cta-wrapper');

                if (currentSlideTitle && currentSlideTitle instanceof HTMLElement) {
                    currentSlideElements.push(currentSlideTitle);
                }

                if (currentSlideExcerpt && currentSlideExcerpt instanceof HTMLElement) {
                    currentSlideElements.push(currentSlideExcerpt);
                }

                if (currentSlideCTA && currentSlideCTA instanceof HTMLElement) {
                    currentSlideElements.push(currentSlideCTA);
                }

                if (active === this.size - 1) {
                    /**
                     * Scroll invite visibiility
                     * Hide
                     */
                    timeline.to(
                        this.$el.querySelector('.highlights-invite'),
                        1,
                        {
                            y: -100,
                            autoAlpha: 0,
                            ease: Power4.easeOut
                        },
                        'start'
                    );
                }

                timeline
                    /**
                     * Set z-index
                     */
                    .set(
                        CURRENT_SLIDE,
                        { zIndex: 9 },
                        'start'
                    )

                    /**
                     * Tween highlights title, excerpt & cta
                     */
                    .staggerFromTo(
                        currentSlideElements,
                        1.4,
                        {
                            autoAlpha: 0,
                            y: DIRECTION * -200
                        },
                        {
                            autoAlpha: 1,
                            y: 0,
                            ease: Power2.easeOut
                        },
                        {
                            from: DIRECTION === -1 ? 'start' : 'end',
                            amount: 0.1
                        },
                        '-=1'
                    )
                    /**
                     * Tween current slide
                     */
                    .fromTo(
                        CURRENT_SLIDE,
                        1,
                        { yPercent: DIRECTION * -100 },
                        {
                            yPercent: 0,
                            ease: Power2.easeInOut
                        },
                        'start'
                    );
            }

            if (active < this.size - 1) {
                /**
                 * Scroll invite visibiility
                 * Toggle visibility
                 */
                timeline
                    .addLabel('invite')
                    .to(
                        this.$el.querySelector('.line-down'),
                        0.5,
                        {
                            scaleY: 1,
                            ease: Power4.easeInOut
                        },
                        'invite'
                    )
                    .to(
                        this.$el.querySelector('.highlights-invite'),
                        1,
                        {
                            y: 0,
                            autoAlpha: 1,
                            ease: Power4.easeOut
                        },
                        'invite'
                    );
            }

            /**
             * Play timeline
             */
            timeline
                .duration(2.4)
                .play();
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created () {
        this.footer = false;
        this.timeline = new TimelineMax(
            {
                delay: 1,
                paused: true,
                immediateRender: true
            }
        );

        this.footerTimeline = new TimelineMax(
            {
                paused: true,
                immediateRender: true,
                yoyo: true,
                onStartScope: this,
                onCompleteScope: this,
                onReverseCompleteScope: this,
                onStart () {
                    this.transition = true;
                },
                onComplete () {
                    this.footer = true;
                    this.transition = false;
                },
                onReverseComplete () {
                    this.footer = false;
                    this.transition = false;
                }
            }
        );
    },

    /**
     * Component mounted event handler
     */
    mounted () {
        // Highlights size
        this.size = document.querySelectorAll('.highlights-item').length;

        // Initialize components
        this.initComponents();

        // Attach global events handler
        this.$event.$on(
            EVENTS.APP_READY,
            this.start
        );
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Initialize components
         * @return {Void}
         */
        initComponents () {
            /**
             * Reset component states
             */
            let resetState = new TimelineMax({ immediateRender: true });
            let introElements = [];
            const NAV = this.$el.querySelector('.highlights-nav');
            const INTRO_TITLE = this.$el.querySelector('.intro h1');
            const INTRO_EXCERPT = this.$el.querySelector('.intro .excerpt');
            const INTRO_CTA = this.$el.querySelector('.intro .cta-wrapper');

            if (INTRO_TITLE && INTRO_TITLE instanceof HTMLElement) {
                introElements.push(INTRO_TITLE);
            }

            if (INTRO_EXCERPT && INTRO_EXCERPT instanceof HTMLElement) {
                introElements.push(INTRO_EXCERPT);
            }

            if (INTRO_CTA && INTRO_CTA instanceof HTMLElement) {
                introElements.push(INTRO_CTA);
            }

            resetState
                .set(
                    [
                        this.$el.querySelectorAll('.infos h2'),
                        this.$el.querySelectorAll('.infos .excerpt'),
                        this.$el.querySelectorAll('.infos .cta-wrapper'),
                        this.$el.querySelectorAll('.highlights-nav li')
                    ],
                    { autoAlpha: 0 }
                )
                .set(
                    [
                        introElements,
                        this.$el.querySelector('.highlights-invite')
                    ],
                    {
                        autoAlpha: 0,
                        y: 200
                    }
                )
                .set(
                    this.$el.querySelector('.line-up'),
                    { scaleY: 0 }
                )
                .set(
                    [
                        this.$el.querySelectorAll('.infos'),
                        this.$el.querySelectorAll('.mask'),
                        document.querySelector('.main-footer')
                    ],
                    { yPercent: 100 }
                );

            /**
             * Register footer timeline
             */
            this.footerTimeline
                .addLabel('start')
                .to(
                    document.querySelector('.main-footer'),
                    1,
                    {
                        yPercent: 0,
                        ease: Power2.easeInOut
                    },
                    'start'
                );

            if (NAV && NAV instanceof HTMLElement) {
                this.footerTimeline.to(
                    this.$el.querySelector('.highlights-nav'),
                    1,
                    {
                        y: -240,
                        ease: Power2.easeInOut
                    },
                    'start'
                );
            }
        },

        /**
         * Set active
         * @param {Number} value
         * @return {Void}
         */
        setActive (value) {
            if (this.enabled) {
                this.active = value;
            }
        },

        /**
         * Move section
         * @param {Boolean} forward - direction
         * @return {Void}
         */
        moveSection (forward = false) {
            if (this.enabled) {
                if (forward) {
                    if (this.active < this.size - 1) {
                        ++this.active;
                    } else if (!this.footer) {
                        this.showFooter();
                    }
                } else {
                    if (this.footer) {
                        this.hideFooter();
                    } else if (this.active > 0) {
                        --this.active;
                    }
                }
            }
        },

        /**
         * Scroll button click handler
         * @return {Void}
         */
        scrollSection () {
            this.moveSection(!this.footer);
        },

        /**
         * Start highlights
         * @return {Void}
         */
        start () {
            this.showExtras();
            this.attachEventsHandlers();
        },

        /**
         * Show extras
         * @return {Void}
         */
        showExtras () {
            const NAV = this.$el.querySelectorAll('.highlights-nav li');

            /**
             * Show first slide
             */
            this.setActive(0);

            /**
             * Create timeline
             */
            let timeline = new TimelineMax(
                {
                    paused: true,
                    onCompleteScope: this,
                    onComplete () {
                        /**
                         * Start loading images
                         */
                        _.each(
                            this.$el.querySelectorAll('.image'),
                            element => {
                                const IMAGE = element.querySelector('img');

                                if (IMAGE && IMAGE instanceof HTMLElement) {
                                    const SOURCE = IMAGE.dataset.src;
                                    const SRCSET = IMAGE.dataset.srcset;

                                    IMAGE.onload = () => {
                                        TweenMax.set(
                                            element,
                                            { className: '+=ready' }
                                        );
                                    };

                                    if (SOURCE) {
                                        IMAGE.removeAttribute('data-src');
                                        IMAGE.setAttribute('src', SOURCE);
                                    }

                                    if (SRCSET) {
                                        IMAGE.removeAttribute('data-srcset');
                                        IMAGE.setAttribute('srcset', SRCSET);
                                    }
                                }
                            }
                        );
                    }
                }
            );

            /**
             * Appending label start
             * Starting point for extras
             */
            timeline.addLabel('start');

            /**
             * Nav elements
             * Anchor list
             */
            if (NAV && NAV instanceof NodeList) {
                timeline.staggerFromTo(
                    NAV,
                    1,
                    {
                        y: 100,
                        autoAlpha: 0
                    },
                    {
                        y: 0,
                        autoAlpha: 1,
                        ease: Power2.easeInOut
                    },
                    {
                        ease: Power2.easeIn,
                        amount: 0.2
                    },
                    'start'
                );
            }

            /**
             * Play timeline
             */
            timeline
                .delay(2)
                .play();
        },

        /**
         * Attach events handlers
         * @return {Void}
         */
        attachEventsHandlers () {
            window.addEventListener(
                'wheel',
                event => {
                    const DELTA = event && (event.deltaY || -event.wheelDeltaY || (event.originalEvent && event.originalEvent.deltaY));

                    if (DELTA && Math.abs(DELTA) > 2 && this.enabled) {
                        this.moveSection(DELTA > 0);
                    }
                },
                { passive: true }
            );

            /**
             * Touch
             */
            window.addEventListener(
                'touchstart',
                event => {
                    this.touchStart = event.pageY || event.changedTouches[0].pageY;
                },
                { passive: true }
            );

            window.addEventListener(
                'touchend',
                event => {
                    const TOUCHEND = event.pageY || event.changedTouches[0].pageY;
                    const DELTA = this.touchStart - TOUCHEND;

                    if (DELTA && Math.abs(DELTA) > 20) {
                        this.moveSection(DELTA > 0);
                    }
                },
                { passive: true }
            );

            // Left / Up pressed
            this.$event.$on(
                [ EVENTS.KEY_UP_LEFT, EVENTS.KEY_UP_TOP ],
                () => {
                    this.moveSection(false);
                }
            );

            // Right / Down pressed
            this.$event.$on(
                [ EVENTS.KEY_UP_RIGHT, EVENTS.KEY_UP_BOTTOM ],
                () => {
                    this.moveSection(true);
                }
            );
        },

        /**
         * Hide footer
         * @return {Void}
         */
        hideFooter () {
            this.footerTimeline
                .reverse();
        },

        /**
         * Show footer
         * @return {Void}
         */
        showFooter () {
            this.footerTimeline
                .play();
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const Group = Vue.extend(groupConfiguration);

/**
 * Module export
 */
export default Group;
