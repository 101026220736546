/**
 * Search Form
 */
import UIkit from 'uikit';
import BasicMixin from '~/mixins/basic';

let searchFormConfiguration = {
    name: 'search-form',

    mixins: [ BasicMixin ],

    props: { selected: String },

    data () {
        return { type: false };
    },

    created () {
        this.type = this.selected || 'any';
    },

    watch: {
        type (current, old) {
            if (old && old !== current) {
                this.submit();
            }
        }
    },

    methods: {
        submit () {
            this.$el.querySelector('[name=post_type]').value = this.type;
            this.$refs.form.submit();
        },

        updateType (type) {
            this.type = type;
            UIkit.dropdown(this.$el.querySelector('.type-choice')).hide(false);
        }
    }
};

const SearchForm = Vue.extend(searchFormConfiguration);

export default SearchForm;
