/**
 * Map selector
 */
import _ from 'lodash';
import BasicMixin from '~/mixins/basic';
import MapsFunctions from '~/mapsfunctions';
import { EVENTS } from '~/config/constant';

let currentMap = null;
// let markerSelected = null;
let globalMarkers = [];
let locationsGroups = [
    {
        ids: ['56', '58', '1915'], // Aircraft manufacturer
        icon: 'map-blue-dark.png',
        label: ''
    },
    {
        ids: ['68', '70', '1917'], // Aerospace equipment and systems
        icon: 'map-blue.png',
        label: ''
    },
    {
        ids: ['60', '62', '1923'], // Logistics and services
        icon: 'map-red.png',
        label: ''
    },
    {
        ids: ['111'], // Head offices
        icon: 'map-grey.png',
        label: ''
    }
];

const showMarker = (markerIconEl, isShow) => {
    if (markerIconEl) {
        if (isShow) {
            markerIconEl.setOpacity(1);
            if (markerIconEl._icon) {
                markerIconEl._icon.classList.remove('hide-marker');
            }
        } else {
            markerIconEl.setOpacity(0);
            if (markerIconEl._icon) {
                markerIconEl._icon.classList.add('hide-marker');
            }
        }
    }
};

const conf = {
    /**
     * Component name
     * @type {String}
     */
    name: 'mapLocationsList',

    props: {
        lang: {
            type: String,
            default: 'fr'
        },

        idmap: {
            type: String,
            default: '1'
        },

        legendes: { type: String },

        markers: { type: String }
    },

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component data
     * @return {Object}
     */
    data () {
        return {
            show: false,
            isLoading: true,
            locations: [],
            activeGroups: []
        };
    },

    /**
     * Component watch data
     */
    watch: {
        activeGroups (value) {
            let result = [];

            if (value.length) {
                let selectedMarkers = [];

                // Collect markers
                for (let i = 0; i < value.length; i++) {
                    const group = locationsGroups.find(grp => grp.ids.includes(value[i]));
                    const markersGroup = globalMarkers.filter(marker => marker.icon === group.icon);

                    selectedMarkers = selectedMarkers.concat(markersGroup);
                }

                /*
                * Hide 2nd instance
                * demande client du 18/05/2021
                * Pour une implantation qui a plusieurs activités, comment fait-on pour privilégier,
                * lorsque tous les filtres sont sélectionnés,
                * l’affichage de l’une ou de l’autre ? Exemple avec Tarbes, par défaut, c’est équipements & systèmes aéronautiques
                * */
                selectedMarkers = selectedMarkers.filter((marker) => {
                    // Get instances with same adress in selectedMarkers
                    const instancesByAdress = selectedMarkers.filter(m => m.address === marker.address);

                    // If we have more than one instance
                    // if the current marker is the first in globalmarkers, we take it
                    if (instancesByAdress.length > 1) {
                        const instancesByAdressInGlobal = globalMarkers.filter(m => m.address === marker.address);

                        return instancesByAdressInGlobal[0].id === marker.id;
                    }

                    return true;
                });
                // End demande client

                result = value.map((id) => {
                    const group = locationsGroups.find(grp => grp.ids.includes(id));
                    const markers = selectedMarkers.filter(marker => marker.icon === group.icon);

                    if (group) {
                        return {
                            ...group,
                            markers: _.sortBy(markers, ['name'])
                        };
                    }

                    return {};
                });
            }

            this.setVisibleMarkers(result);
            this.locations = result;
        }
    },

    /**
     * Component mounted event handler
     */
    mounted () {
        MapsFunctions.addListener(this.idmap, (mapsmarkerObj) => {
            /*
            mapsmarkerObj.on('maploaded', (map) => {
                map.zoomSnap = 0.5;
                map.zoomDelta = 0.5;
            });
            */

            mapsmarkerObj.on('markersloaded', (_mmpMarkers) => {
                currentMap = mapsmarkerObj.map;
                currentMap.fitBounds(Object.values(_mmpMarkers).map(marker => {
                    const { lat, lng } = marker._latlng;

                    return [lat, lng];
                }));
                this.initLocationsGroup(Object.values(_mmpMarkers));
                this.isLoading = false;
                console.log('_mmpMarkers ---> ', _mmpMarkers);
            });
        });

        // Listen for type selection
        this.$event.$on(EVENTS.TYPE_MARKER, (ids) => {
            this.show = true;
            this.activeGroups = ids;
            MapsFunctions.refreshMap(this.idmap, 250);
        });
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        onSelectLocation (markerObj) {
            currentMap.setView({ ...markerObj._latlng }, markerObj.zoom);
            setTimeout(() => {
                markerObj.openTooltip();
                // markerSelected = markerObj;
            }, 275);
        },

        setVisibleMarkers (currentLocations) {
            const visibleMarkers = currentLocations.reduce((accu, currentValue) => {
                return accu.concat(currentValue.markers);
            }, []);

            globalMarkers.forEach((element) => {
                if (visibleMarkers.findIndex(m => m.id === element.id) === -1) {
                    showMarker(element, false);
                } else {
                    showMarker(element, true);
                }
            });
        },

        initLocationsGroup (mmpMarkers) {
            const legendsObjects = JSON.parse(this.legendes);
            const inputMarkers = _.cloneDeep(JSON.parse(this.markers));

            globalMarkers = [];

            // Inject zoom
            if (mmpMarkers) {
                mmpMarkers.forEach((element) => {
                    const relatedMapMarker = inputMarkers.find(m => m.id === element.id);

                    if (relatedMapMarker) {
                        element.name = element.name.trim();
                        element.zoom = parseFloat(relatedMapMarker.zoom);
                    }
                });

                globalMarkers = mmpMarkers;
            }

            locationsGroups = locationsGroups.map((group) => {
                const grp = { ...group };

                for (const [key, value] of Object.entries(legendsObjects)) {
                    if (grp.ids.includes(key)) {
                        grp.label = value;
                        break;
                    }
                }

                return grp;
            });

            this.activeGroups = Object.keys(legendsObjects);
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const MapLocationsList = Vue.extend(conf);

/**
 * Module export
 */
export default MapLocationsList;
export { locationsGroups };
