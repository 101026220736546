/**
 * Search Layer
 */
import axios from 'axios';
import _ from 'lodash';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { TimelineMax, Power4 } from 'gsap';
import { mapState } from 'vuex';
import BasicMixin from '~/mixins/basic';
import { EVENTS } from '../config/constant';

let searchLayerConfiguration = {
    name: 'search-layer',

    mixins: [ BasicMixin ],

    props: {
        service: String,
        lang: String,
        base: String,
        message: String
    },

    data () {
        return {
            query: '',
            current: false,
            loading: false,
            results: false
        };
    },

    watch: {
        query (current) {
            if (current && current.length > 2) {
                this.doSearch();
            }
        },

        search () {
            if (this.timeline) {
                this.timeline
                    .duration(0.6)
                    .reversed(!this.timeline.reversed());
            }
        }
    },

    computed: {
        ...mapState([ 'menu', 'search' ]),

        actualites () {
            return _.filter(this.results, { type: 'post' });
        },

        pages () {
            return _.filter(this.results, { type: 'page' });
        },

        url () {
            return {
                main: `${this.base}?s=${this.current}`,
                actu: `${this.base}?s=${this.current}&post_type=post`,
                page: `${this.base}?s=${this.current}&post_type=page`
            };
        }
    },

    created () {
        this.cancelToken = axios.CancelToken;
        this.request = null;
        this.timeline = new TimelineMax(
            {
                paused: true,
                immediateRender: true,
                yoyo: true,
                onStartScope: this,
                onReverseCompleteScope: this,
                onCompleteScope: this,
                onStart () {
                    disableBodyScroll(this.$el.querySelector('.search-results-container'));
                    this.$store
                        .dispatch('hideMenu');
                },
                onReverseComplete () {
                    enableBodyScroll(this.$el.querySelector('.search-results-container'));
                    this.reset();
                }
            }
        );

        this.$event.$on(EVENTS.SEARCH_DISPLAY, this.toggleSearchLayer);
    },

    mounted () {
        this.buildTimeline();
    },

    updated () {
        console.log('search upodated');
        this.$event.dispatch(EVENTS.CONTENT_UPDATED);
    },

    methods: {
        reset () {
            const results = this.$el.querySelector('.search-results-container');

            this.abortRequest();

            this.query = '';
            this.loading = false;
            this.current = false;
            this.results = false;
            this.$refs.field.value = '';

            if (results instanceof HTMLElement) {
                results.scrollTop = 0;
            }
        },

        buildTimeline () {
            if (this.timeline) {
                this.timeline
                    .from(
                        this.$el,
                        1,
                        {
                            autoAlpha: 0,
                            ease: Power4.easeOut
                        }
                    )
                    .from(
                        this.$el.querySelector('.search-content'),
                        1,
                        {
                            autoAlpha: 0,
                            y: 100,
                            ease: Power4.easeOut
                        },
                        0.4
                    )
                    .reverse();
            }
        },

        toggleSearchLayer () {
            this.$store
                .dispatch('toggleSearch');
        },

        abortRequest () {
            if (this.request) {
                this.request.cancel('abort');
            }
        },

        doSearch () {
            let params = new FormData();

            this.abortRequest();

            this.request = this.cancelToken.source();
            this.loading = true;

            params.append('action', 'search');
            params.append('query', this.query);
            params.append('lang', this.lang);

            axios
                .post(
                    this.service,
                    params,
                    { cancelToken: this.request.token }
                )
                .then(
                    response => {
                        this.loading = false;

                        if (response && response.status === 200 && response.data) {
                            const results = this.$el.querySelector('.search-results-container');

                            this.results = response.data.results;
                            this.current = response.data.query;

                            if (results instanceof HTMLElement) {
                                results.scrollTop = 0;
                            }
                        }
                    }
                )
                .catch(
                    error => {
                        console.log('[Search] %s', error.message);

                        if (error.message && error.message !== 'abort') {
                            this.loading = false;
                        }
                    }
                );
        },

        submit () {
            this.abortRequest();
        }
    }
};

const SearchLayer = Vue.extend(searchLayerConfiguration);

export default SearchLayer;
