/**
 * RSE Engagements slider
 */

import Swiper from 'swiperslider';
import UIkit from 'uikit';
import BasicMixin from '~/mixins/basic';
import { SWIPER, EVENTS } from '~/config/constant';

let rseEngagementsConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'rse-engagements',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted () {
        const CONFIG = _.assign(
            SWIPER,
            {
                slidesPerView: 'auto',
                centerInsufficientSlides: true,
                setWrapperSize: false,
                pagination: {
                    el: this.$el.querySelector('.swiper-pagination'),
                    bulletActiveClass: 'active',
                    clickable: true
                },
                on: {
                    slideChangeTransitionEnd: () => {
                        _.each(
                            this.$el.querySelectorAll('.image'),
                            image => {
                                UIkit.update(image);
                            }
                        );
                    }
                }
            }
        );

        this.slider = new Swiper(this.$refs.slider, CONFIG);

        this.$event.$on(
            EVENTS.APP_READY,
            () => {
                this.slider.init();
            }
        );
    }
};

/**
 * Register component
 * @type {Vue}
 */
const RSEEngagements = Vue.extend(rseEngagementsConfiguration);

/**
 * Module export
 */
export default RSEEngagements;
