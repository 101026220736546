/**
 * Basic mixin
 */

import events from '~/utils/events';
import store from '~/store/main';

/**
 * Shared configuration
 * @type {Object}
 */
const basicMixin = {
    /**
     * Name config
     * @type {String}
     */
    name: String,

    /**
     * Storage
     * @type {Vuex}
     */
    store,

    /**
     * Created event handler
     * @return {Void}
     */
    created () {
        console.info('🔸 %s created', this.$options.name.toUpperCase());

        // Dispatch global event refs
        this.$event = events;
    },

    /**
     * Mounted event handler
     * @return {Void}
     */
    mounted () {
        console.info('🔹 %s mounted', this.$options.name.toUpperCase());
    },

    /**
     * Updated event handler
     * @return {Void}
     */
    updated () {
        console.info('🔹 %s updated', this.$options.name.toUpperCase());
    },

    /**
     * Destroyed event handler
     * @return {Void}
     */
    destroyed () {
        console.info('🔸 %s destroyed', this.$options.name.toUpperCase());
    }
};

/**
 * Export configuration
 */
export default basicMixin;
