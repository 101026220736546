/**
 * Root application
 * Main vue instance
 */

import Modernizr from 'modernizr';
import UIkit from 'uikit';
import SimpleBar from 'simplebar';
import { mapState } from 'vuex';
import Clipboard from 'v-clipboard';
import { APP, EVENTS } from '~/config/constant';
import basicMixin from '~/mixins/basic';
import pageHeader from '~/components/page-header';
import actufilter from '~/components/actu-filter';
import highlights from '~/components/highlights';
import group from '~/components/group';
import archives from '~/components/archives';
import agendas from '~/components/agenda';
import portrait from '~/components/portrait';
import rseEngagements from '~/components/rse-engagements';
import socialWall from '~/components/social-wall';
import enjeux from '~/components/enjeux';
import relatedPosts from '~/components/related-posts';
import contact from '~/components/contact';
import searchForm from '~/components/search-form';
import { TweenMax } from 'gsap';
import MapsFunctions from '~/mapsfunctions';
import mapZoneSelector from '~/components/map-zone-selector';
import mapLocationsList from '~/components/map-locations-list';
import mapTypeMarkersSelector from '~/components/map-type-markers-selector';

// Disable tips on production
Vue.config.devtools = APP.DEBUG;
Vue.config.productionTip = APP.TIP;
Vue.use(Clipboard);

// UIKit global config
UIkit.mixin(
    { data: { easing: 'cubic-bezier(0.36, 0.32, 0.12, 0.98)' } },
    'slider'
);

let stylesheets = document.querySelectorAll('link[as=style]');
let daherConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: APP.NAME,

    /**
     * Component target element
     * DOM Element
     * @type {HTMLElement}
     */
    el: document.getElementById('daher'),

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ basicMixin ],

    /**
     * Child components
     */
    components: {
        pageHeader,
        highlights,
        group,
        actufilter,
        archives,
        agendas,
        portrait,
        rseEngagements,
        enjeux,
        socialWall,
        relatedPosts,
        contact,
        searchForm,
        mapZoneSelector,
        mapLocationsList,
        mapTypeMarkersSelector
    },

    /**
     * Component computed data
     */
    computed: {
        /**
         * Map store state
         */
        ...mapState([ 'menu', 'guide' ]),

        /**
         * App lang
         */
        lang () {
            return this.$el.dataset.lang;
        }
    },

    /**
     * Data watchers
     */
    watch: {
        /**
         * Visual guide watcher
         * @return  {Void}
         */
        guide () {
            this.toggleVisualGuide();
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created () {
        console.log('App created');
        this.useCasesComponents = false;
    },

    /**
     * Component mounted handler
     * @return  {Void}
     */
    async mounted () {
        await this.loadAssets();

        this.toggleVisualGuide();
        this.attachEventsHandlers();
        this.updateLazyLoad();
        this.buildGlobalComponentBehaviors();
        this.updateGlobalComponents();
        this.setupEphotoEdam();

        this.$event.$on(
            EVENTS.KEY_UP_ESCAPE,
            () => {
                this.escapeKeyPress();
            }
        );

        this.$event.$on(
            EVENTS.WINDOW_RESIZE,
            () => {
                this.updateGlobalComponents();
            }
        );

        this.$event.$on(
            EVENTS.CONTENT_UPDATED,
            () => {
                this.updateLazyLoad();
            }
        );

        /**
         * Set app ready
         */
        document.body.classList.add('app-ready');

        /**
         * Dispatch ready state
         */
        this.$event.dispatch(EVENTS.APP_READY);
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Toggle visual guide
         * @return {Void}
         */
        toggleVisualGuide () {
            if (this.guide) {
                document.body.classList.add('guide-visible');
            } else {
                document.body.classList.remove('guide-visible');
            }
        },

        /**
         * Load assets
         * @return {Promise}
         */
        loadAssets () {
            return new Promise(
                resolve => {
                    /**
                     * Load stylesheets
                     */
                    if (module.hot) {
                        /**
                         * Load plugins stylesheets
                         */
                        _.each(
                            stylesheets,
                            stylesheet => {
                                stylesheet.setAttribute('rel', 'stylesheet');
                                stylesheet.removeAttribute('as');
                            }
                        );

                        resolve();
                    } else {
                        /**
                         * Load stylesheets
                         */
                        _.each(
                            stylesheets,
                            stylesheet => {
                                if (stylesheet.getAttribute('id') === 'daher/stylesheet') {
                                    stylesheet.addEventListener(
                                        'load',
                                        () => {
                                            const googleMapApi = document.getElementById('mmp-googlemaps');

                                            if (googleMapApi) {
                                                this.invalidateMap();
                                            }

                                            // Dispatch promise resolution
                                            resolve();
                                        }
                                    );
                                }

                                stylesheet.setAttribute('rel', 'stylesheet');
                                stylesheet.removeAttribute('as');
                            }
                        );
                    }
                }
            );
        },

        /**
         * Invalidate implantations map
         * @return {Void}
         */
        invalidateMap () {
            let invalidateTimer = false;

            console.log('-- invalidate maps --');

            invalidateTimer = setInterval(
                () => {
                    if (window.mapsMarkerPro && window.google && window.google.maps && window.google.maps.Map) {
                        clearInterval(invalidateTimer);

                        _.each(
                            window.mapsMarkerPro,
                            mapElement => {
                                if (mapElement.map && mapElement.map.options) {
                                    console.log('invalidate:', mapElement.uid);
                                    mapElement.map.invalidateSize();
                                }
                            }
                        );
                    }
                },
                250
            );
        },

        /**
         * Attach events handlers
         * @return {Void}
         */
        attachEventsHandlers () {
            document.getElementById('toggle-guide')
                .addEventListener(
                    'click',
                    () => {
                        this.$store.dispatch('toggleGuide');
                    }
                );
        },

        /**
         * Escape key presse
         * @return {Void}
         */
        escapeKeyPress () {
            /**
             * Hide menu
             */
            if (this.menu) {
                this.$store
                    .dispatch('toggleMenu');
            }
        },

        /**
         * Build global component behaviors
         * @return {Void}
         */
        buildGlobalComponentBehaviors () {
            /**
             * Setting simple bar on details
             */
            _.each(
                this.$el.querySelectorAll('.offre-detail .detail-inner'),
                detail => new SimpleBar(detail)
            );

            /**
             * History anchors
             */
            UIkit.scrollspyNav(
                '.anchors-history',
                {
                    cls: 'active',
                    scroll: true
                }
            );

            UIkit.scrollspy(
                '.histoire-content',
                {
                    target: '.date, .date-child, .histoire-item, .illustration',
                    cls: 'visible',
                    hidden: false,
                    repeat: true,
                    offsetTop: -60
                }
            );

            /**
             * Update components
             */
            UIkit.update(document.body);

            /**
             * Page top trigger
             */
            UIkit.util.on(
                '#page-top-trigger',
                'outview',
                () => this.$event.dispatch(EVENTS.APP_TOP_OUTVIEW)
            );

            UIkit.util.on(
                '#page-top-trigger',
                'inview',
                () => this.$event.dispatch(EVENTS.APP_TOP_INVIEW)
            );

            /**
             * Use cases
             */
            this.setUpUseCases();
        },

        /**
         * Update lazy load
         * @return {Void}
         */
        updateLazyLoad () {
            console.log('update lazy load');

            /**
             * Scan for .lazyload
             * Add scroll spy
             */
            UIkit.scrollspy(
                '.lazyload',
                { hidden: false }
            );

            /**
             * Set inview handler
             * Will start image load
             */
            UIkit.util.on(
                '.lazyload',
                'inview',
                event => {
                    const ELEMENT = event.currentTarget;
                    const IMAGE = ELEMENT.querySelector('img');

                    if (IMAGE) {
                        const SOURCE = IMAGE.dataset.src;
                        const SRCSET = IMAGE.dataset.srcset;

                        IMAGE.addEventListener(
                            'load',
                            () => {
                                ELEMENT.classList.add('ready');
                                this.$event.dispatch(EVENTS.IMAGE_LOADED);
                            },
                            false
                        );

                        if (SOURCE) {
                            IMAGE.removeAttribute('data-src');
                            IMAGE.setAttribute('src', SOURCE);
                        }

                        if (SRCSET) {
                            IMAGE.removeAttribute('data-srcset');
                            IMAGE.setAttribute('srcset', SRCSET);
                        }
                    }
                }
            );

            /**
             * Immediate load
             */
            _.each(
                document.querySelectorAll('.immediateload'),
                element => {
                    const IMAGE = element.querySelector('img');

                    element.classList.remove('immediateload');

                    if (IMAGE) {
                        const SOURCE = IMAGE.dataset.src;
                        const SRCSET = IMAGE.dataset.srcset;

                        IMAGE.addEventListener(
                            'load',
                            () => {
                                element.classList.add('ready');
                                this.$event.dispatch(EVENTS.IMAGE_LOADED);
                            },
                            false
                        );

                        if (SOURCE) {
                            IMAGE.removeAttribute('data-src');
                            IMAGE.src = SOURCE;
                        }

                        if (SRCSET) {
                            IMAGE.removeAttribute('data-srcset');
                            IMAGE.srcset = SRCSET;
                        }
                    }
                }
            );

            /**
             * Unset lazyload classname
             */
            _.each(
                document.querySelectorAll('.lazyload'),
                element => {
                    element.classList.remove('lazyload');
                }
            );
        },

        /**
         * Setup use cases
         * @return {Void}
         */
        setUpUseCases () {
            const USE_CASES = document.querySelectorAll('.bloc-use-cases');

            _.each(
                USE_CASES,
                useCase => {
                    const ITEMS = useCase.querySelectorAll('li');
                    const IMAGES = useCase.querySelectorAll('.images .media-wrapper');

                    _.each(
                        ITEMS,
                        (item, index) => {
                            const ACTIONS = item.querySelectorAll('.action-toggle');

                            _.each(
                                ACTIONS,
                                action => {
                                    action.addEventListener(
                                        'click',
                                        () => {
                                            // Remove previous active
                                            _.each(ITEMS, list => list.classList.remove('active'));
                                            _.each(IMAGES, image => image.classList.remove('active'));

                                            // Set current active
                                            item.classList.add('active');
                                            IMAGES[index].classList.add('active');
                                        }
                                    );
                                }
                            );
                        }
                    );
                }
            );
        },

        /**
         * Update global components
         * @return {Void}
         */
        updateGlobalComponents () {
            const USE_CASES = document.querySelectorAll('.bloc-use-cases');

            if (this.$event.screen.width >= 960) {
                if (this.useCasesComponents) {
                    this.useCasesComponents.$destroy();
                    this.useCasesComponents = false;
                }

                _.each(
                    USE_CASES,
                    useCase => {
                        useCase.querySelector('ul').classList.remove('uk-slider-items');
                        useCase.querySelector('.content-list').classList.remove('uk-slider');
                        useCase.querySelector('.content-list').classList.remove('uk-slider-container');

                        TweenMax.set(
                            useCase.querySelector('ul'),
                            { clearProps: 'all' }
                        );
                    }
                );
            } else {
                _.each(
                    USE_CASES,
                    useCase => {
                        useCase.querySelector('ul').classList.add('uk-slider-items');
                    }
                );

                this.useCasesComponents = UIkit.slider(
                    '.bloc-use-cases .content-list',
                    { finite: true }
                );
            }
        },

        /**
         * Copy to clipboard success handler
         * @return {Void}
         */
        clipboardSuccessHandler () {
            const message = this.lang === 'fr' ? 'URL copié dans le presse papier' : 'URL copied to clipboard';

            /**
             * Throw notification
             */
            UIkit.notification({
                message: message,
                status: 'primary',
                pos: 'bottom-center',
                timeout: 2000
            });
        },

        /**
         * Open popup window
         * @param {String} uri - Target url
         * @param {Number} width - Window width
         * @param {Number} height - Window height
         * @return {Void}
         */
        pop (uri, width = 600, height = 300) {
            if (uri) {
                window.open(uri, '_blank', 'width=' + width + ',height=' + height);
            }
        },

        setupEphotoEdam () {
            let EphotoDam = {
                zoomIn: function (img) {
                    const overlay = window.$('#ephoto-dam-overlay').length ? window.$('#ephoto-dam-overlay') : window.$('<div' +
                        ' id="ephoto-dam-overlay"></div>').appendTo('body');
                    const wrap = window.$('#ephoto-dam-wrap').length ? window.$('#ephoto-dam-wrap') : window.$('<div' +
                        ' id="ephoto-dam-wrap"><div id="ephoto-dam-close"></div><img></div>').appendTo('body');

                    const buffer = new Image();

                    buffer.onload = function () {
                        let width;
                        let height;
                        let ratiop = document.documentElement.clientHeight / document.documentElement.clientWidth;
                        let ratiof = buffer.height / buffer.width;

                        if (ratiop > ratiof) {
                            width = document.documentElement.clientWidth - 100;
                            height = Math.round(width * ratiof);
                        } else {
                            height = document.documentElement.clientHeight - 100;
                            width = Math.round(height * (1 / ratiof));
                        }

                        const close = function (event) {
                            event.stopPropagation();
                            event.stopImmediatePropagation();

                            wrap.fadeOut(300);
                            overlay.delay(250).fadeOut(300);
                        };

                        wrap.children()
                            .last()
                            .attr('src', img.src);

                        wrap.children()
                            .first()
                            .on('click', close);

                        wrap.css(
                            {
                                'width': width,
                                'height': height,
                                'margin-left': -(Math.floor(width / 2) + 8),
                                'margin-top': -(Math.floor(height / 2) + 8)

                            })
                            .delay(250)
                            .fadeIn(300);

                        overlay.fadeIn(300).on('click', close);
                    };

                    buffer.src = img.src;
                }
            };

            window.$('img.ephoto-dam-zoom').on('click', function (event) {
                event.stopPropagation();
                event.stopImmediatePropagation();

                EphotoDam.zoomIn(this);
            });
        }
    }
};

/**
 * Create vue instance
 */
let buildApp = () => {
    /**
     * Set css vars
     */
    document.documentElement.style.setProperty('--viewport-h', `${window.innerHeight}px`);

    /**
     * Build vue app
     */
    window.daherInstance = new Vue(daherConfiguration);
};

/**
 * Scroll restoration
 */
if (Modernizr.history && 'scrollRestoration' in window.history) {
    history.scrollRestoration = 'manual';
} else {
    window.scrollTo(0, 0);
}

/**
 * Hot reload
 * Accept update
 */
if (module.hot) {
    module.hot.accept();

    if (window.daherInstance) {
        console.info('%c-- %s --', 'color: green; font-weight: bold;', 'RELOADING LOCATION');
        window.location.reload();
    }
}

/**
 * Define map function
 * */
window.onMapMarkerCreated = function (mmp) {
    const tmpMmp = MapsFunctions.getMMP(mmp.id);

    if (tmpMmp === undefined) {
        MapsFunctions.addMMP(mmp);
    }

    mmp.on('maploaded', function () {
        MapsFunctions.setLoaded(this.id);
    });
};

/**
 * Start build app on window load
 */
window.addEventListener('load', buildApp);
