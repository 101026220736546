/**
 * Portrait slider
 */

import Swiper from 'swiperslider';
import UIkit from 'uikit';
import BasicMixin from '~/mixins/basic';
import { SWIPER, EVENTS } from '~/config/constant';
import { TimelineMax, TweenMax, Power4 } from 'gsap';

let portraitConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'portrait',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component created event handler
     * @return {Void}
     */
    created () {
        this.expandTween = false;
    },

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted () {
        let config = {};

        _.assign(
            config,
            SWIPER,
            {
                slidesPerView: 'auto',
                centerInsufficientSlides: true,
                setWrapperSize: false,
                pagination: {
                    el: this.$el.querySelector('.swiper-pagination'),
                    bulletActiveClass: 'active',
                    clickable: true
                },
                on: {
                    slideChangeTransitionEnd: () => {
                        _.each(
                            this.$el.querySelectorAll('.image'),
                            image => {
                                UIkit.update(image);
                            }
                        );
                    }
                }
            }
        );

        this.slider = new Swiper(this.$refs.slider, config);

        this.$event.$on(
            EVENTS.APP_READY,
            () => {
                this.slider.init();
            }
        );
    },

    /**
     * Component methods
     */
    methods: {
        /**
         * Expand item
         * @param {Number} id
         * @return {Void}
         */
        expand (id) {
            if (this.slider) {
                const WRAPPER = this.$el.querySelectorAll('.swiper-wrapper');

                this.expandTween = new TimelineMax(
                    {
                        paused: true,
                        onCompleteScope: this,
                        onComplete () {
                            TweenMax.set(
                                WRAPPER,
                                { className: '+=ending' }
                            );

                            TweenMax.set(
                                WRAPPER,
                                {
                                    className: '-=ending',
                                    delay: 1
                                }
                            );

                            this.slider.update();
                            this.slider.slideTo(id);
                            this.expandTween.clear(true);
                        }
                    }
                );

                this.expandTween.addLabel('start');

                _.each(
                    this.$el.querySelectorAll('.swiper-slide'),
                    (item, index) => {
                        if (index === id) {
                            this.expandTween
                                .to(
                                    item,
                                    1,
                                    {
                                        className: '+=open',
                                        ease: Power4.easeOut
                                    },
                                    'start'
                                )
                                .set(
                                    item,
                                    { className: '+=opened' }
                                );
                        } else {
                            if (item.classList.contains('open')) {
                                this.expandTween
                                    .set(
                                        item,
                                        { className: '-=opened' },
                                        'start'
                                    )
                                    .to(
                                        item,
                                        1,
                                        {
                                            className: '-=open',
                                            ease: Power4.easeOut
                                        },
                                        'start'
                                    );
                            }
                        }
                    }
                );

                this.expandTween
                    .duration(0.8)
                    .play();
            }
        },

        /**
         * Close item
         * @param {Number} id
         * @return {Void}
         */
        close (id) {
            const CURRENT = this.$el.querySelectorAll('.swiper-slide')[id];
            const WRAPPER = this.$el.querySelectorAll('.swiper-wrapper');

            TweenMax.set(
                WRAPPER,
                { className: '+=ending' }
            );

            TweenMax.set(
                CURRENT,
                { className: '-=opened' }
            );

            TweenMax.to(
                CURRENT,
                1,
                {
                    className: '-=open',
                    ease: Power4.easeOut,
                    onCompleteScope: this,
                    onComplete () {
                        this.slider.update();

                        TweenMax.set(
                            WRAPPER,
                            {
                                className: '-=ending',
                                delay: 1
                            }
                        );
                    }
                }
            );
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const Portrait = Vue.extend(portraitConfiguration);

/**
 * Module export
 */
export default Portrait;
