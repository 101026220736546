/**
 * Main storage
 */

import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Modernizr from 'modernizr';

/**
 * Load vuex plugin
 */
Vue.use(Vuex);

/**
 * Store states
 * @type {Object}
 */
const state = {
    guide: false,
    menu: false,
    search: false,
    archivesFilter: {
        publication: false,
        category: false,
        type: false
    }
};

/**
 * Store mutations
 * @type {Object}
 */
const mutations = {
    /**
     * Mutate state
     * @param {Object} state
     * @param {Object} payload
     */
    updateState (state, payload) {
        _.assign(state, payload);
    }
};

/**
 * Store actions
 * @type {Object}
 */
const actions = {
    /**
     * Toggle menu
     * @param  {Object}
     * @return {Boolean}
     */
    toggleMenu: ({ commit, state }) => commit('updateState', { menu: !state.menu }),
    hideMenu: ({ commit }) => commit('updateState', { menu: false }),

    /**
     * Toggle search
     * @param  {Object}
     * @return {Boolean}
     */
    toggleSearch: ({ commit, state }) => commit('updateState', { search: !state.search }),
    hideSearch: ({ commit }) => commit('updateState', { search: false }),

    /**
     * Toggle guide
     * @param  {Object}
     * @return {Boolean}
     */
    toggleGuide: ({ commit, state }) => commit('updateState', { guide: !state.guide }),

    /**
     * Update archives filter
     * @param {Object}
     * @param {Object}
     * @return {Object}
     */
    updateArchivesFilter: ({ commit }, payload) => commit('updateState', { archivesFilter: payload })
};

/**
 * Vuex persisted state configuration
 */
const vuexPersistedStateConfiguration = {
    key: 'daher-storage',
    paths: [ 'guide' ]
};

/**
 * Main storage configuration
 */
const mainStorageConfiguration = {
    state,
    mutations,
    actions,
    plugins: [],
    strict: process.env.NODE_ENV !== 'production'
};

/**
 * Set storage type
 */
if (Modernizr.localstorage) {
    vuexPersistedStateConfiguration.storage = window.localStorage;
} else if (Modernizr.sessionstorage) {
    vuexPersistedStateConfiguration.storage = window.sessionStorage;
}

/**
 * Storage allowed
 * Create store persisted state
 */
if (Modernizr.localstorage || Modernizr.sessionstorage) {
    mainStorageConfiguration.plugins.push(createPersistedState(vuexPersistedStateConfiguration));
}

/**
 * Vuex storage instance
 * @type {Vuex}
 */
const mainStorage = new Vuex.Store(mainStorageConfiguration);

/**
 * Module export
 */
export default mainStorage;
