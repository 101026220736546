/**
 * Enjeux slider
 */

import Swiper from 'swiperslider';
import SimpleBar from 'simplebar';
import UIkit from 'uikit';
import BasicMixin from '~/mixins/basic';
import { SWIPER, EVENTS } from '~/config/constant';

let enjeuxConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'enjeux',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted () {
        let config = {};

        /**
         * Setting swiper configuration
         */
        _.assign(
            config,
            SWIPER,
            {
                slidesPerView: 'auto',
                centerInsufficientSlides: true,
                setWrapperSize: false,
                pagination: {
                    el: this.$el.querySelector('.swiper-pagination'),
                    bulletActiveClass: 'active',
                    clickable: true
                },
                on: {
                    slideChangeTransitionEnd: () => {
                        _.each(
                            this.$el.querySelectorAll('.image'),
                            image => {
                                UIkit.update(image);
                            }
                        );
                    }
                }
            }
        );

        /**
         * Setting simple bar on details
         */
        _.each(
            this.$el.querySelectorAll('.details'),
            detail => new SimpleBar(detail)
        );

        this.slider = new Swiper(this.$refs.slider, config);

        this.$event.$on(
            EVENTS.APP_READY,
            () => {
                this.slider.init();
            }
        );
    }
};

/**
 * Register component
 * @type {Vue}
 */
const Enjeux = Vue.extend(enjeuxConfiguration);

/**
 * Module export
 */
export default Enjeux;
