/**
 * Map type merkers selector
 */
import BasicMixin from '~/mixins/basic';
import MapsFunctions from '~/mapsfunctions';
import { EVENTS } from '~/config/constant';
import { locationsGroups } from '~/components/map-locations-list';

const conf = {
    /**
     * Component name
     * @type {String}
     */
    name: 'mapTypeMarkersSelector',

    props: {
        legendes: { type: String },

        idmap: {
            type: String,
            default: '1'
        }
    },

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component data
     * @return {Object}
     */
    data () {
        return {
            typesList: [],
            show: false
        };
    },

    /**
     * Component mounted event handler
     */
    mounted () {
        const legendsObjects = JSON.parse(this.legendes);
        const legendsEntries = Object.entries(legendsObjects);

        this.typesList = locationsGroups.map(grp => {
            const legend = legendsEntries.find(([id]) => grp.ids.includes(id));

            if (legend) {
                return {
                    id: legend[0],
                    label: legend[1],
                    isActive: true
                };
            }

            return {};
        });

        /*
        this.typesList = Object.entries(legendsObjects).map(([id, label]) => {
            return {
                id,
                label,
                isActive: true
            };
        });
        */

        MapsFunctions.addListener(this.idmap, (mapsmarkerObj) => {
            mapsmarkerObj.on('markersloaded', () => {
                this.show = true;
            });
        });
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        onSelectType (typeId) {
            const isAllSelected = this.typesList.findIndex(type => type.isActive === false) === -1;

            this.typesList = this.typesList.map((type) => {
                const tmpType = { ...type };

                if (isAllSelected) {
                    tmpType.isActive = tmpType.id === typeId;
                } else {
                    if (tmpType.id === typeId) {
                        tmpType.isActive = !tmpType.isActive;
                    }
                }

                return tmpType;
            });

            const hasNoSelection = this.typesList.findIndex(type => type.isActive === true) === -1;

            if (hasNoSelection) {
                this.typesList = this.typesList.map((type) => {
                    const tmpType = { ...type };

                    tmpType.isActive = tmpType.id !== typeId;

                    return tmpType;
                });
            }

            this.$event.$emit(EVENTS.TYPE_MARKER, this.typesList.filter(type => {
                return type.isActive === true;
            }).map(typeActive => {
                return typeActive.id;
            }));
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const MapTypeMarkersSelector = Vue.extend(conf);

/**
 * Module export
 */
export default MapTypeMarkersSelector;
