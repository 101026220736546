/**
 * Enjeux slider
 */

import BasicMixin from '~/mixins/basic';
import { TimelineMax, Power4, Power2 } from 'gsap';

let contactConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'contact',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component props
     * @type {Object}
     */
    props: {
        defaultLabel: {
            type: String,
            default: 'Vous souhaitez...'
        }
    },

    /**
     * Component data
     * @return {Object}
     */
    data () {
        return {
            selection: false,
            label: false
        };
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Selection label
         * @return {String}
         */
        selectionLabel () {
            return this.label || this.defaultLabel;
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created () {
        this.dropdownVisible = false;
        this.dropdownTimeline = new TimelineMax(
            {
                paused: true,
                immediateRender: true,
                yoyo: true
            }
        );
    },

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted () {
        this.buildTimelines();
        this.attachEventListeners();
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Build animation timelines
         * @return {Void}
         */
        buildTimelines () {
            this.dropdownTimeline
                .addLabel('start')
                .fromTo(
                    this.$refs.dropdown,
                    0.5,
                    {
                        y: 60,
                        autoAlpha: 0
                    },
                    {
                        y: 0,
                        autoAlpha: 1,
                        ease: Power4.easeOut
                    },
                    'start'
                )
                .to(
                    this.$refs.icon,
                    0.3,
                    {
                        className: '+=active',
                        ease: Power2.easeOut
                    },
                    'start'
                );
        },

        /**
         * Attach global event listeners
         * @return {Void}
         */
        attachEventListeners () {
            document.addEventListener('click', this.hideDropdown);
        },

        /**
         * Toggle dropdown
         * @return {Void}
         */
        toggleDropdown () {
            if (this.dropdownVisible) {
                this.dropdownTimeline.reverse();
            } else {
                this.dropdownTimeline.play();
            }

            this.dropdownVisible = !this.dropdownVisible;
        },

        /**
         * Hide dropdown
         * @return {Void}
         */
        hideDropdown () {
            if (this.dropdownVisible) {
                this.dropdownTimeline.reverse();
            }

            this.dropdownVisible = false;
        },

        /**
         * Select item
         * @param {String} value
         * @param {String} label
         */
        select (value, label) {
            this.selection = value;
            this.label = label;
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const Contact = Vue.extend(contactConfiguration);

/**
 * Module export
 */
export default Contact;
