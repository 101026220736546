/**
 * Archives
 */
import axios from 'axios';
import UIkit from 'uikit';
import { mapState } from 'vuex';
import { TimelineMax, TweenMax, Power4 } from 'gsap';
import { ARCHIVES, EVENTS } from '~/config/constant';
import BasicMixin from '~/mixins/basic';

let archivesConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'archives',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component props
     */
    props: {
        /**
         * Service url
         */
        service: String,

        /**
         * Action name
         */
        action: String,

        /**
         * Lang
        */
        postlang: String
    },

    /**
     * Component data
     * @return {Object}
     */
    data () {
        return {
            loading: false,
            clear: false,
            more: true,
            page: 0
        };
    },

    /**
     * Computed data
     * @type {Object}
     */
    computed: {
        /**
         * Map store state
         */
        ...mapState([ 'archivesFilter' ])
    },

    /**
     * Data watchers
     */
    watch: {
        /**
         * Filters watcher
         * @param {Object} value
         * @return {Void}
         */
        archivesFilter: {
            handler () {
                /**
                 * Reset pagination
                 */
                this.page = 0;

                /**
                 * Do filter
                 */
                this.doFilter();
            },
            deep: true
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created () {
        this.cancelToken = axios.CancelToken;
        this.archivesRequest = false;
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    mounted () {
        console.log('--- action %s ---', this.action);

        this.$event.$on(ARCHIVES.UPDATE, this.update);
        this.$event.$on(EVENTS.IMAGE_LOADED, this.updateMasonry);
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        /**
         * Update archives
         * @param {Object} config
         * @return {Void}
         */
        update (config) {
            let state = _.cloneDeep(this.archivesFilter);
            let value = _.get(state, config.type) === config.value ? false : config.value;

            /**
             * Merge incoming config
             */
            _.set(state, config.type, value);

            /**
             * Dispatch changes
             */
            this.$store
                .dispatch('updateArchivesFilter', state);
        },

        /**
         * Do filter
         * @return {Void}
         */
        doFilter () {
            let filterTimeline = new TimelineMax(
                { onComplete: this.doRequest }
            );

            filterTimeline
                /**
                 * Hide wrapper
                 */
                .to(
                    [
                        this.$refs.wrapper,
                        this.$refs.loadMore
                    ],
                    0.5,
                    {
                        autoAlpha: 0,
                        ease: Power4.easeOut
                    },
                    '-=0.4'
                );
        },

        /**
         * Load more
         * @return {Void}
         */
        loadMore () {
            let loadMoreTimeline = new TimelineMax(
                {
                    onStartScope: this,
                    onStart () {
                        ++this.page;
                    },
                    onComplete: this.doRequest,
                    onCompleteParams: [ true ]
                }
            );

            loadMoreTimeline
                .set(
                    this.$refs.filterLoader,
                    { autoAlpha: 0 }
                );
        },

        /**
         * Do request
         * @return {Void}
         */
        doRequest (append = false) {
            let params = new FormData();
            let requestparams = _.cloneDeep(this.archivesFilter);

            params.append('date', requestparams.publication);
            params.append('actu-type', requestparams.type);
            params.append('category', requestparams.category);
            params.append('cp', requestparams.cp);
            params.append('offset', this.page);
            params.append('action', this.action);
            params.append('postlang', this.postlang);

            if (this.archivesRequest) {
                this.archivesRequest.cancel('abort');
            }

            this.archivesRequest = this.cancelToken.source();
            this.loading = true;
            this.clear = !append;

            axios
                .post(
                    this.service,
                    params,
                    { cancelToken: this.archivesRequest.token }
                )
                .then(
                    response => {
                        this.loading = false;
                        this.clear = false;

                        if (response && response.status === 200 && response.data) {
                            /**
                             * Reset filter loader
                             */
                            TweenMax.set(
                                this.$refs.filterLoader,
                                { clearProps: 'all' }
                            );

                            /**
                             * Appending contents
                             */
                            TweenMax.to(
                                this.$refs.wrapper,
                                0.5,
                                {
                                    autoAlpha: 1,
                                    clearProps: 'all',
                                    ease: Power4.easeOut,
                                    onStartScope: this,
                                    onCompleteScope: this,
                                    onStart () {
                                        if (append) {
                                            /**
                                             * Append contents
                                             */
                                            this.$el.querySelector('.news-list-masonry').innerHTML += response.data.actus;
                                        } else {
                                            /**
                                             * Replace contents
                                             */
                                            this.$refs.container.innerHTML = response.data.actus;
                                        }

                                        /**
                                         * Toggle load more
                                         */
                                        TweenMax.set(
                                            this.$refs.loadMore,
                                            { autoAlpha: response.data.more ? 1 : 0 }
                                        );

                                        /**
                                         * Update masonry
                                         */
                                        this.updateMasonry();
                                    },
                                    onComplete () {
                                        this.$event.dispatch(EVENTS.CONTENT_UPDATED);
                                    }
                                }
                            );
                        }
                    }
                )
                .catch(
                    error => {
                        console.log('request error', error);

                        if (error.message && error.message !== 'abort') {
                            this.loading = false;
                            this.clear = false;

                            if (append) {
                                // Restore pagination
                                --this.page;
                            }
                        }
                    }
                );
        },

        /**
         * Update masonry
         * @return {Void}
         */
        updateMasonry () {
            // UIkit.update(this.$el.querySelector('.news-list-masonry'));
            _.each(
                this.$el.querySelectorAll('.image'),
                image => {
                    UIkit.update(image);
                }
            );
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const Archives = Vue.extend(archivesConfiguration);

/**
 * Module export
 */
export default Archives;
