const MapsFunctions = {
    maps: [],

    getMMP: function (id) {
        return this.maps.find(mmpInst => mmpInst.mapmakerpro.id === id);
    },

    getMap: function (id) {
        const tmpMMP = this.getMMP(id);

        if (tmpMMP && tmpMMP.isLoaded) {
            return tmpMMP.mapmakerpro.map;
        }

        return null;
    },

    addMMP: function (mmp) {
        if (mmp && mmp.id) {
            if (this.getMMP(mmp.id) === undefined) {
                this.maps.push({
                    mapmakerpro: mmp,
                    isLoaded: false,
                    listeners: []
                });
            }
        }
    },

    setLoaded: function (id) {
        const tmpMMP = this.getMMP(id);

        if (tmpMMP) {
            tmpMMP.isLoaded = true;
            if (tmpMMP.listeners.length) {
                for (const func of tmpMMP.listeners) {
                    if (typeof func === 'function') {
                        func(tmpMMP.mapmakerpro);
                    }
                }
            }
        }
    },

    setMapView: function (idMMP, viewConf) {
        const map = this.getMap(idMMP);

        if (map) {
            map.setView(viewConf, viewConf.zoom);
        }
    },

    refreshMap: function (idMMP, delay) {
        const map = this.getMap(idMMP);

        if (map) {
            if (delay) {
                setTimeout(() => {
                    map.invalidateSize(true);
                }, delay);
            } else {
                map.invalidateSize(true);
            }
        }
    },

    openTooltip: function (idMMP, tooltipConf) {
        const map = this.getMap(idMMP);

        if (map) {
            map.openTooltip(tooltipConf.content, tooltipConf.latlang);
        }
    },

    addListener: function (idMMP, callback) {
        const tmpMMP = this.getMMP(idMMP);

        if (tmpMMP) {
            tmpMMP.listeners.push(callback);
        }
    }
};

export default MapsFunctions;
