/**
 * Global constant
 */

export const APP = {
    /**
     * Application namespace
     */
    NAME: 'daher',

    /**
     * Debug mode
     */
    DEBUG: DEBUG,
    TIP: false
};

export const EVENTS = {
    /**
     * App events
     */
    APP_READY: 'app-ready',
    APP_TOP_INVIEW: 'app-top-inview',
    APP_TOP_OUTVIEW: 'app-top-outview',

    /**
     * Window events
     */
    WINDOW_RESIZE: 'window-resize',
    WINDOW_RESIZE_IMMEDIATE: 'window-resize-immediate',

    /**
     * Key events
     */
    KEY_UP_ESCAPE: 'key-up-escape',
    KEY_UP_LEFT: 'key-up-left',
    KEY_UP_RIGHT: 'key-up-right',
    KEY_UP_TOP: 'key-up-top',
    KEY_UP_BOTTOM: 'key-up-bottom',

    /**
     * Content
     */
    CONTENT_UPDATED: 'content-updated',
    IMAGE_LOADED: 'content-updated',

    /**
     * Search
     */
    SEARCH_DISPLAY: 'search-display',

    /**
     * MAP
     */
    TYPE_MARKER: 'type_marker'
};

export const ARCHIVES = {
    /**
     * Archives update
     */
    UPDATE: 'archive_update'
};

export const SWIPER = {
    loop: false,
    speed: 600,
    grabCursor: true,
    navigation: false,
    watchOverflow: true,
    /* slidesPerView: 'auto', */
    /* Fix iOs out of bound unreachable items */
    setWrapperSize: true,
    threshold: 5,
    /* touchEventsTarget: 'wrapper', */
    roundLengths: true,
    init: false
};
