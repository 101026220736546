/**
 * Page header
 */

import Modernizr from 'modernizr';
import UIkit from 'uikit';
import { mapState } from 'vuex';
import { TweenMax, Power4, Power2, TimelineMax } from 'gsap';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { EVENTS, ARCHIVES } from '~/config/constant';
import searchLayer from '~/components/search-layer';
import BasicMixin from '~/mixins/basic';

let pageHeaderConfiguration = {
    /**
     * Component name
     * @type {String}
     */
    name: 'page-header',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component computed data
     */
    computed: {
        /**
         * Map store state
         */
        ...mapState([ 'menu', 'archivesFilter' ])
    },

    data () {
        return {
            checkedCp: [],
            mainCategories: [{ cp: true }]
        };
    },

    /**
     * Child components
     */
    components: { searchLayer },

    /**
     * Component data watchers
     * @type {Object}
     */
    watch: {
        /**
         * Menu watcher
         * @param {Boolean} active - menu state
         */
        menu (active) {
            if (this.timeline) {
                if (active) {
                    this.timeline
                        .duration(2.4)
                        .play();
                } else {
                    this.timeline
                        .duration(1.4)
                        .reverse();
                }
            }
        }
    },

    /**
     * Component created event handler
     * @return {Void}
     */
    created () {
        this.timeline = new TimelineMax(
            {
                paused: true,
                immediateRender: true,
                yoyo: true,
                onStartScope: this,
                onReverseCompleteScope: this,
                onStart () {
                    const MENU = this.$el.querySelector('.header-inner');

                    // Disable body scroll
                    disableBodyScroll(this.$el.querySelector('.main-menu'));

                    // Add open classname on menu full
                    if (MENU && MENU instanceof HTMLElement) {
                        TweenMax.set(
                            MENU,
                            { className: '+=open' }
                        );
                    }

                    // Close search
                    this.$store.dispatch('hideSearch');
                },
                onReverseComplete () {
                    const MENU = this.$el.querySelector('.header-inner');

                    // Enable body scroll
                    enableBodyScroll(this.$el.querySelector('.main-menu'));

                    // Remove open classname on menu full
                    if (MENU && MENU instanceof HTMLElement) {
                        TweenMax.set(
                            MENU,
                            { className: '-=open' }
                        );
                    }

                    // Close menu items
                    this.resetMenu();
                    this.clearMenuHighlights();
                }
            }
        );
    },

    /**
     * Component mounted event handler
     * @return {Void}
     */
    mounted () {
        this.registerTimeline();
        this.buildGlobalComponentBehaviors();
        this.attachEventsHandlers();

        this.$event.$on(
            EVENTS.WINDOW_RESIZE,
            () => {
                this.resetMenu();
                this.clearMenuHighlights();
            }
        );

        this.$event.$on(
            EVENTS.APP_TOP_INVIEW,
            this.removeBannerBackground
        );

        this.$event.$on(
            EVENTS.APP_TOP_OUTVIEW,
            this.setBannerBackground
        );
    },

    /**
     * Component methods
     */
    methods: {
        /**
         * Toggle menu
         * @return  {Void}
         */
        toggleMenu () {
            this.$store
                .dispatch('toggleMenu');
        },

        check: function () {
            if (!this.checkedCp[0]) {
                this.archiveFilter('cp', 'true');
            } else {
                this.archiveFilter('cp', 'false');
            }
        },

        /**
         * Attach events handlers
         * @return {Void}
         */
        attachEventsHandlers () {
            /* const MENU_CONTAINER = this.$el.querySelectorAll('.main-menu .menu'); */
            const MENU_ITEMS = this.$el.querySelectorAll('.menu > li');

            /**
             * Main menu items hover & out
             */
            _.each(
                MENU_ITEMS,
                element => {
                    const TRIGGER = element.querySelector('.trig > *');
                    const EXPAND = element.querySelector('.expand');
                    const SUBMENU = element.querySelector('.submenu');
                    let resetTimeout = false;

                    if (TRIGGER instanceof HTMLElement) {
                        TRIGGER.addEventListener(
                            'mouseenter',
                            () => {
                                if ((!Modernizr.touchevents || Modernizr.pointerevents) && this.$event.screen.width >= 960) {
                                    if (resetTimeout) {
                                        clearTimeout(resetTimeout);
                                    }

                                    this.clearMenuHighlights();
                                    this.setMenuHighlight(element);
                                }
                            }
                        );
                    }

                    if (SUBMENU instanceof HTMLElement) {
                        SUBMENU.addEventListener(
                            'mouseenter',
                            () => {
                                if ((!Modernizr.touchevents || Modernizr.pointerevents) && this.$event.screen.width >= 960) {
                                    if (resetTimeout) {
                                        clearTimeout(resetTimeout);
                                    }

                                    this.clearMenuHighlights();
                                    this.setMenuHighlight(element);
                                }
                            }
                        );
                    }

                    if (EXPAND && EXPAND instanceof HTMLButtonElement) {
                        EXPAND.addEventListener(
                            'click',
                            () => {
                                if (element.classList.contains('highlighted')) {
                                    this.resetMenu();
                                    this.clearMenuHighlights();
                                } else {
                                    this.clearMenuHighlights();
                                    this.setMenuHighlight(element);
                                }
                            }
                        );
                    }

                    element.addEventListener(
                        'mouseleave',
                        () => {
                            if ((!Modernizr.touchevents || Modernizr.pointerevents) && this.$event.screen.width >= 960) {
                                this.resetMenu();

                                resetTimeout = setTimeout(
                                    () => {
                                        this.clearActiveMenuHighlights(element);
                                    },
                                    1000
                                );
                            }
                        }
                    );
                }
            );
        },

        /**
         * Set highlighted menu item
         * @param {HTMLElement} element - Target element
         * @return {Void}
         */
        setMenuHighlight (element) {
            const MENU_CONTAINER = this.$el.querySelectorAll('.main-menu .menu');
            const SUBMENU = element.querySelector('.submenu');

            // Add higlighted classname to main element
            element.classList.add('highlighted');

            // Set main container hover state
            _.each(
                MENU_CONTAINER,
                container => {
                    container.classList.add('hover');
                }
            );

            // Show submenu
            if (SUBMENU && SUBMENU instanceof HTMLElement) {
                TweenMax.to(
                    SUBMENU,
                    0.6,
                    {
                        className: '+=visible',
                        ease: Power4.easeOut
                    }
                );
            }
        },

        /**
         * Clear highlighted menu items
         * @return {Void}
         */
        clearMenuHighlights () {
            _.each(
                this.$el.querySelectorAll('.highlighted'),
                element => {
                    this.clearActiveMenuHighlights(element);
                }
            );
        },

        /**
         * Clear highlighted menu items
         * @param {HTMLElement} element - Menu item
         * @return {Void}
         */
        clearActiveMenuHighlights (element) {
            const SUBMENU = element.querySelector('.submenu');

            // Remove all main item higlighted classname
            element.classList.remove('highlighted');

            // Hide main items submenu
            if (SUBMENU && SUBMENU instanceof HTMLElement) {
                TweenMax.to(
                    SUBMENU,
                    0.4,
                    {
                        className: '-=visible',
                        ease: Power4.easeOut
                    }
                );
            }
        },

        /**
         * Reset menu
         * Clear highlights & clear hover
         * @return {Void}
         */
        resetMenu () {
            const MENU_CONTAINER = this.$el.querySelectorAll('.main-menu .menu');

            // Unset main container hover state
            _.each(
                MENU_CONTAINER,
                container => {
                    container.classList.remove('hover');
                }
            );
        },

        /**
         * Register timeline
         * @return {Void}
         */
        registerTimeline () {
            this.timeline
                .fromTo(
                    this.$el.querySelector('.main-menu-mask'),
                    1,
                    { autoAlpha: 0 },
                    {
                        autoAlpha: 1,
                        ease: Power4.easeOut
                    }
                )
                .fromTo(
                    this.$el.querySelector('.main-menu'),
                    1,
                    { yPercent: -100 },
                    {
                        yPercent: 0,
                        zIndex: 2,
                        ease: Power4.easeInOut
                    },
                    '-=0.8'
                )
                .staggerFromTo(
                    this.$el.querySelectorAll('.menu > li, .main-menu .nav-lang'),
                    1.4,
                    {
                        alpha: 0,
                        y: 100
                    },
                    {
                        alpha: 1,
                        y: 0,
                        ease: Power4.easeOut
                    },
                    {
                        ease: Power2.easeOut,
                        amount: 0.5
                    },
                    '-=0.2'
                )
                .staggerFromTo(
                    this.$el.querySelectorAll('.social li'),
                    0.6,
                    {
                        alpha: 0,
                        x: 50
                    },
                    {
                        alpha: 1,
                        x: 0,
                        ease: Power4.easeOut
                    },
                    {
                        ease: Power2.easeOut,
                        amount: 0.1
                    },
                    '-=0.5'
                )
                .staggerFromTo(
                    this.$el.querySelectorAll('.menusOtherSite li'),
                    0.6,
                    {
                        alpha: 0,
                        x: -50
                    },
                    {
                        alpha: 1,
                        x: 0,
                        ease: Power4.easeOut
                    },
                    {
                        ease: Power2.easeOut,
                        amount: 0.1
                    },
                    '-=0.5'
                );
        },

        /**
         * Build component behaviors
         * @return {Void}
         */
        buildGlobalComponentBehaviors () {
            /**
             * Main menu anchors
             */
            UIkit.scrollspyNav(
                '.anchors-menu',
                {
                    cls: 'active',
                    scroll: true,
                    offset: 70
                }
            );
        },

        /**
         * Set banner background
         * @return {Void}
         */
        setBannerBackground () {
            this.$el.querySelector('.header-inner').classList.add('bg-enable');
        },

        /**
         * Remove banner background
         * @return {Void}
         */
        removeBannerBackground () {
            this.$el.querySelector('.header-inner').classList.remove('bg-enable');
        },

        /**
         * Archive filtering
         * @param {String} type
         * @param {Number|String} value
         * @return {Void}
         */
        archiveFilter (type, value) {
            /**
             * Force hide drop downs
             */
            _.each(
                this.$el.querySelectorAll('.filter-choice'),
                element => {
                    UIkit.dropdown(element).hide(false);
                }
            );

            /**
             * Dispatch update
             */
            this.$event.dispatch(
                ARCHIVES.UPDATE,
                {
                    type: type,
                    value: value
                }
            );
        },

        /**
         * Toggle search layer
         */
        toggleSearch () {
            this.$event.$emit(EVENTS.SEARCH_DISPLAY);
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const PageHeader = Vue.extend(pageHeaderConfiguration);

/**
 * Module export
 */
export default PageHeader;
