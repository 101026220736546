/**
 * Page header
 */
import UIkit from 'uikit';
import { mapState } from 'vuex';
import { ARCHIVES } from '~/config/constant';
import BasicMixin from '~/mixins/basic';

let actuFilterConfig = {
    /**
     * Component name
     * @type {String}
     */
    name: 'actufilter',

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component computed data
     */
    computed: {
        /**
         * Map store state
         */
        ...mapState([ 'menu', 'archivesFilter' ])
    },

    data () {
        return {
            checkedCp: [],
            mainCategories: [{ cp: true }]
        };
    },

    /**
     * Component methods
     */
    methods: {
        check: function () {
            if (!this.checkedCp[0]) {
                this.archiveFilter('cp', 'true');
            } else {
                this.archiveFilter('cp', 'false');
            }
        },

        /**
         * Archive filtering
         * @param {String} type
         * @param {Number|String} value
         * @return {Void}
         */
        archiveFilter (type, value) {
            /**
             * Force hide drop downs
             */
            _.each(
                this.$el.querySelectorAll('.filter-choice'),
                element => {
                    UIkit.dropdown(element).hide(false);
                }
            );

            /**
             * Dispatch update
             */
            this.$event.dispatch(
                ARCHIVES.UPDATE,
                {
                    type: type,
                    value: value
                }
            );
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const ActuFilter = Vue.extend(actuFilterConfig);

/**
 * Module export
 */
export default ActuFilter;
