/**
 * Map selector
 */
import BasicMixin from '~/mixins/basic';
import MapsFunctions from '~/mapsfunctions';

const translations = {
    en: {
        geaograhicalArea: 'Geographical area',
        northAmerica: 'North America',
        europe: 'Europe',
        northAfrica: 'North Africa',
        asia: 'Asia'
    },
    fr: {
        geaograhicalArea: 'Zone géographique',
        northAmerica: 'Amérique du nord',
        europe: 'Europe',
        northAfrica: 'Afrique du nord',
        asia: 'Asie'
    },
    de: {
        geaograhicalArea: 'Geographisches Gebiet',
        northAmerica: 'Nordamerika',
        europe: 'Europa',
        northAfrica: 'Nordafrika',
        asia: 'Asien'
    }
};

let map = null;

let conf = {
    /**
     * Component name
     * @type {String}
     */
    name: 'mapZoneSelector',

    props: {
        lang: {
            type: String,
            default: 'fr'
        },

        idmap: {
            type: String,
            default: '1'
        }
    },

    /**
     * Component mixins
     * @type {Array}
     */
    mixins: [ BasicMixin ],

    /**
     * Component data
     * @return {Object}
     */
    data () {
        return {
            allZones: [
                {
                    id: 4,
                    label: this.getTranslation('geaograhicalArea'),
                    bounds: null,
                    isSelected: false
                },
                {
                    id: 0,
                    label: this.getTranslation('europe'),
                    bounds: [
                        [53.348860922, 7.425342704],
                        [35.753130265, 23.907665109]
                    ],
                    isSelected: false
                },
                {
                    id: 1,
                    label: this.getTranslation('asia'),
                    bounds: [
                        [46.146837877, 74.711192911],
                        [2.039336183, 146.254161661]
                    ],
                    isSelected: false
                },
                {
                    id: 2,
                    label: this.getTranslation('northAfrica'),
                    bounds: [
                        [36.999605655, -13.311268027],
                        [26.132400747, 12.308849183]
                    ],
                    isSelected: false
                },
                {
                    id: 3,
                    label: this.getTranslation('northAmerica'),
                    bounds: [
                        [53.348860922, -125.682178637],
                        [18.758031378, -75.101123950]
                    ],
                    isSelected: false
                }
            ],
            isZoneSelectorOpen: false,
            show: false,
            selectedZone: {},
            geographicalZones: []
        };
    },

    /**
     * Component mounted event handler
     */
    mounted () {
        MapsFunctions.addListener(this.idmap, (mapsmarkerObj) => {
            mapsmarkerObj.on('markersloaded', (markers) => {
                map = mapsmarkerObj.map;
                this.allZones[0].bounds = Object.values(markers).map(marker => {
                    const { lat, lng } = marker._latlng;

                    return [lat, lng];
                });
                this.show = true;
            });
        });

        this.setZones(4);
    },

    /**
     * Component methods
     * @type {Object}
     */
    methods: {
        getTranslation (id) {
            return translations[this.lang][id];
        },

        setZones (id) {
            this.selectedZone = this.allZones.find(zone => zone.id === id);
            this.geographicalZones = this.allZones.filter(zone => zone.id !== id);
        },

        setMapView (id) {
            const conf = this.geographicalZones.find(conf => conf.id === id);

            map.fitBounds(conf.bounds);
            MapsFunctions.refreshMap(this.idmap, 250);
        },

        onSelectMapZone (id) {
            this.isZoneSelectorOpen = false;
            this.setMapView(id);
            this.setZones(id);
        },

        showZones () {
            this.isZoneSelectorOpen = !this.isZoneSelectorOpen;
        }
    }
};

/**
 * Register component
 * @type {Vue}
 */
const MapZoneSelector = Vue.extend(conf);

/**
 * Module export
 */
export default MapZoneSelector;
